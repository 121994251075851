import {
  CartIcon,
  CouponsIcon,
  DepartmentsIcon,
  MenuIcon,
  SearchIcon,
  UserIcon,
} from "@/icons"
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useAuth } from "contexts/AuthContext"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import generateConfig from "../../configs/config"
import SearchField from "./SearchField"
import SideNavBar, { NavBarItemType } from "./SideNavBar"
import styles from "../../styles/component.module.scss"
import { ExpandMore } from "@mui/icons-material"
import { gaLogEvent, EventName } from "utils/googleAnalyticsEvents"
import { AppContext } from "contexts/AppContext"
import { useChainConfigurationQuery } from "generated/graphql"
import axios from "axios"
import { getEligibilitiesList } from "index.service"
import { setEligibiitiesList } from "contexts/actions/actions"
import { useQuery } from "@tanstack/react-query"
import TaxonomyApi from "services/TaxonomyApi"
import { swiftly } from "client-data-bom/client-data-bom"
import Departments from "./Departments"

const config = generateConfig()

export default function TopNavBar() {
  const theme = useTheme()
  const isMobile = useMediaQuery("(max-width:767px)")
  const isTablet = useMediaQuery("(min-width:767px) and (max-width:1279px)")
  const isDesktop = useMediaQuery("(min-width:1279px)")
  const isSm = useMediaQuery("(min-width:600px) and (max-width:767px)")
  const { t } = useTranslation()
  const router = useRouter()
  const { isAuthenticated, user, initialState } = useAuth()
  const { dispatch } = useContext(AppContext)

  const getEligibilites = async () => {
    const data = await getEligibilitiesList()
    if (data) {
      dispatch(setEligibiitiesList(data.eligibilitiesList))
    }
  }

  useEffect(() => {
    getEligibilites()
  }, [])

  const [isSideNavBarOpen, setIsSideNavBarOpen] = useState(false)
  const [isDepartmentsVisible, setIsDepartmentsVisible] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const departmentsAnchorRef = React.useRef<HTMLElement>(null)

  let width
  switch (config.configBanner) {
    case "luckysupermarkets":
      width = 157
      break
    case "foodmaxx":
      width = 110
      break
    case "savemart":
      width = 142
      break
    default:
      width = 142
  }

  const { data: taxonomy } = useQuery(["taxonomies"], () => {
    return TaxonomyApi.fetchTaxonomy()
  })

  const taxonomyGraph = useMemo(() => {
    return taxonomy?.graph || []
  }, [taxonomy])

  const taxonomies = useMemo(() => {
    return taxonomyGraph.reduce((acc, node) => {
      acc[node.id] = node
      return acc
    }, {} as { [key: string]: swiftly.taxonomy.SwiftlyJsTaxonomyNode })
  }, [taxonomyGraph])

  const productDepartments = useMemo(() => {
    const productRootCategory = taxonomyGraph?.find((taxonomy) => {
      return taxonomy.id === config.taxonomyNodeId
    })
    return (productRootCategory?.children || [])
      .map((id) => taxonomies[id])
      .filter((department) => !!department)
      .filter((department) => department?.id !== "*")
  }, [taxonomyGraph, taxonomies])

  const handleSideNavBarToggle = () => {
    setIsSideNavBarOpen(!isSideNavBarOpen)
  }

  const Label: React.FC<{ label: string }> = ({ label }: { label: string }) => (
    <Typography color="textPrimary.main">{label}</Typography>
  )

  const navItemGroups: NavBarItemType[][] = [
    // Group 1
    [
      {
        label: (
          <Typography
            noWrap
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "30ch",
            }}
            color="textPrimary.main"
            sx={{ fontWeight: 600, fontSize: 15 }}
            ref={departmentsAnchorRef}
          >
            {t("navBarMenu.departments")}
          </Typography>
        ),
        onClick: () => {
          setIsDepartmentsVisible(!isDepartmentsVisible)
        },
        icon: (
          <DepartmentsIcon
            color={theme.palette.ctaPrimary.main}
            fontSize="1.5rem"
          />
        ),
        endIcon: (
          <ExpandMore
            sx={{
              ml: "auto",
              color: theme.palette.ctaPrimary.main,
            }}
          />
        ),
        props: {
          "data-cy": "products-menu",
          "aria-label": `${t("navBarMenu.departments")} ${t(
            "ariaLabels.listOfItems",
            { count: productDepartments.length + 1 }
          )} 
            ${
              isDepartmentsVisible
                ? t("ariaLabels.expanded")
                : t("ariaLabels.collapsed")
            }
          `,
        },
      },
      {
        label: <Label label={t("navBarMenu.coupons")} />,
        href: "/coupons",
        onClick: () => {
          gaLogEvent({
            eventName: EventName.navTop_click,
            parameters: {
              text_click: t("navBarMenu.coupons"),
              link_sendTo: "/coupons",
              item_location: "Primary",
            },
          })
        },
        icon: (
          <CouponsIcon
            color={theme.palette.ctaPrimary.main}
            fontSize="1.5rem"
          />
        ),
        props: {
          "aria-label": t("navBarMenu.coupons"),
          role: "link",
        },
      },
      {
        label: <Label label={t("navBarMenu.shopNow")} />,
        href: `${config?.shopNow || ""}`,
        icon: <CartIcon fontSize="1.5rem" />,
        props: {
          "aria-label": t("navBarMenu.shopNowAriaLabel"),
          role: "link",
        },
      },
    ],
  ]

  if (config?.configBanner !== "foodmaxx" && config?.madeToOrder) {
    navItemGroups[0].push(
      {
        label: <Label label={t("navBarMenu.madeToOrder")} />,
        href: `${config?.madeToOrder || ""}`,
        icon: <CartIcon fontSize="1.5rem" />,
        props: {
          "aria-label": t("navBarMenu.shopNowAriaLabel"),
          role: "link",
        },
      }
    )
  }

  // Set auth nav option dynamically
  if (isAuthenticated) {
    const navItem = {
      label: user?.firstName || "",
      href: "/accounts",
      icon: (
        <UserIcon color={theme.palette.ctaPrimary.main} fontSize="1.5rem" />
      ),
      props: {
        role: "link",
      },
    }
    navItemGroups.push([navItem])
  } else {
    const navItem = {
      desktopPosition: "right",
      label: t("navBarMenu.signIn"),
      icon: (
        <UserIcon color={theme.palette.ctaPrimary.main} fontSize="1.5rem" />
      ),
      href: "/login",
      props: { "data-cy": "sign-in", "aria-label": "sign-in", role: "link" },
    }
    navItemGroups.push([navItem])
  }

  const sideMenuOptions = (
    <>
      {isMobile && (
        <>
          <IconButton
            data-cy="mob-search-btn"
            onClick={() => setIsSearchOpen(true)}
            aria-label={t("ariaLabels.searchBar")}
            role="button"
          >
            <SearchIcon
              color={theme.palette.ctaPrimary.main}
              fontSize="1.5rem"
            />
          </IconButton>
        </>
      )}

      <IconButton
        data-cy="mob-profile-btn"
        onClick={() => {
          router.push({ pathname: "/accounts" })
          localStorage.setItem("from", window.location.pathname)
        }}
        aria-label={t("navBarMenu.profileAriaLabel")}
        role="link"
        sx={{ ml: "0.5rem !important" }}
      >
        <UserIcon color={theme.palette.ctaPrimary.main} fontSize="1.5rem" />
      </IconButton>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ ml: "0.5rem !important" }}
      />
      <IconButton
        data-cy="nav-side-bar"
        onClick={handleSideNavBarToggle}
        aria-label={t("navBarMenu.navBarButtonAriaLabel")}
        role="button"
        sx={{ ml: "0.5rem !important" }}
      >
        <MenuIcon color={theme.palette.ctaPrimary.main} />
      </IconButton>
    </>
  )

  if (!isDesktop && isSearchOpen)
    return (
      <Stack mx={2} width="100%" direction="row" justifyContent="space-between">
        <SearchField />
        <Button
          onClick={() => setIsSearchOpen(false)}
          color="textPrimary"
          variant="text"
          sx={{ ml: 1, mr: -1 }}
          aria-label={t("navBarMenu.cancel")}
        >
          {t("navBarMenu.cancel")}
        </Button>
      </Stack>
    )

  return (
    <Box className={styles.wrapperH} flex={1} py={1}>
      <Container
        maxWidth="xl"
        sx={{
          pr: isSm
            ? "1rem !important"
            : isMobile
            ? "0.5rem !important"
            : isTablet
            ? "1rem !important"
            : "1rem !important",
        }}
      >
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Link href="/" className={styles.logo_link}>
            {/* needed to stop forward ref error */}
            <Image
              src={config.siteLogoDark}
              alt={config.configBanner}
              aria-label={config.configBanner}
              width={width}
              height={32}
              priority
            />
          </Link>

          {isMobile && (
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={1}
              justifyContent="flex-end"
              sx={{ width: "100%" }}
              className={styles.mobileNav}
            >
              {sideMenuOptions}
            </Stack>
          )}

          {isTablet && (
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              sx={{ width: "100%" }}
            >
              <Box mr={1} sx={{ flexGrow: 1 }}>
                <SearchField />
              </Box>
              {sideMenuOptions}
            </Stack>
          )}

          {isDesktop && (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              {navItemGroups
                ?.slice(0, navItemGroups.length - 1)
                .map((navItems, i) => (
                  <React.Fragment key={i}>
                    {navItems?.map(
                      (
                        {
                          label,
                          icon,
                          href,
                          onClick,
                          props,
                          endIcon,
                        }: NavBarItemType,
                        i: number
                      ) => (
                        <Button
                          key={`nav-mobile-${i}`}
                          startIcon={icon}
                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                            const target = e.target as HTMLButtonElement
                            if (target.textContent === t("navBarMenu.signIn")) {
                              gaLogEvent({
                                eventName: EventName.login_click,
                                parameters: {
                                  text_click: target.textContent,
                                  item_location: window.location.pathname,
                                },
                              })
                            } else {
                              gaLogEvent({
                                eventName:
                                  isAuthenticated && href === "/accounts"
                                    ? EventName.profile_click
                                    : EventName.navTop_click,
                                parameters: {
                                  text_click:
                                    isAuthenticated && href === "/accounts"
                                      ? "Profile"
                                      : target.textContent,
                                  link_sendTo:
                                    isAuthenticated && href === "/accounts"
                                      ? "/accounts"
                                      : href,
                                  item_location: "Primary",
                                },
                              })
                            }
                            e.preventDefault()
                            if (href) {
                              if (href === config?.shopNow || href === config?.madeToOrder) {
                                window.open(href, "_blank")
                              } else {
                                router.push({ pathname: href })
                              }
                            } else if (
                              onClick &&
                              typeof onClick === "function"
                            ) {
                              onClick(e)
                            }
                          }}
                          {...props}
                          variant="label"
                          endIcon={endIcon}
                          sx={{
                            px: 1,
                            fontWeight: 600,
                            fontSize: 15,
                            minWidth: "fit-content",
                            color: "textPrimary.main",
                          }}
                        >
                          {label}
                        </Button>
                      )
                    )}
                  </React.Fragment>
                ))}

              {/* Search box */}
              <Box width="100%">
                <SearchField />
              </Box>

              {navItemGroups?.[navItemGroups.length - 1]?.map(
                (
                  {
                    label,
                    icon,
                    href,
                    onClick,
                    props,
                    endIcon,
                  }: NavBarItemType,
                  i: number
                ) => (
                  <Button
                    key={`nav-mobile-${i}`}
                    startIcon={icon}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      const target = e.target as HTMLButtonElement
                      if (target.textContent === t("navBarMenu.signIn")) {
                        gaLogEvent({
                          eventName: EventName.login_click,
                          parameters: {
                            text_click: target.textContent,
                            item_location: window.location.pathname,
                          },
                        })
                      } else {
                        gaLogEvent({
                          eventName:
                            isAuthenticated && href === "/accounts"
                              ? EventName.profile_click
                              : EventName.navTop_click,
                          parameters: {
                            text_click:
                              isAuthenticated && href === "/accounts"
                                ? "Profile"
                                : target.textContent,
                            link_sendTo:
                              isAuthenticated && href === "/accounts"
                                ? "/accounts"
                                : href,
                            item_location: "Primary",
                          },
                        })
                      }
                      e.preventDefault()
                      if (href) {
                        router.push({ pathname: href })
                      } else if (onClick && typeof onClick === "function") {
                        onClick(e)
                      }
                    }}
                    {...props}
                    variant="label"
                    sx={{
                      px: 1,
                      fontWeight: 600,
                      fontSize: 15,
                      minWidth: "fit-content",
                      color: "textPrimary.main",
                    }}
                    aria-label={label}
                  >
                    {label}
                  </Button>
                )
              )}
            </Stack>
          )}

          {!isDesktop && (
            <SideNavBar
              open={isSideNavBarOpen}
              onClose={handleSideNavBarToggle}
              setIsDepartmentsVisible={setIsDepartmentsVisible}
            />
          )}
        </Stack>
      </Container>

      <Departments
        setIsSideNavBarOpen={setIsSideNavBarOpen}
        anchorElement={departmentsAnchorRef.current}
        isOpen={isDepartmentsVisible}
        onClose={() => setIsDepartmentsVisible(false)}
        isMobile={isMobile}
        productDepartments={productDepartments}
        taxonomies={taxonomies}
        taxonomy={taxonomy}
      />
    </Box>
  )
}
